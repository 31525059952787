@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-1000 {
    animation-delay: 1s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
  /* .home-background {
    background-image: url('./assets/banner-bg.png')
      url('./assets/banner-bg.png');
    background-blend-mode: overlay;
    opacity: 1;
  } */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'burtons';
  src: url('./assets/fonts/Burtons.otf');
}
@font-face {
  font-family: 'poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'klee-one';
  src: url('./assets/fonts/KleeOne-Regular.ttf');
}
@font-face {
  font-family: 'centrano2-book';
  src: url('./assets/fonts/CentraNo2-Book.ttf');
}
@font-face {
  font-family: "AgustinaRegular";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Agustina.woff") format("woff");
}